<template>
  <div
    style="width: 100% !important;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;"
    class="recipes"
  >
    <button
      class="button is-info is-light z-10"
      @click="isExportServiceModalActive = true"
    >
      <span>Baixar relatório de serviços</span>
    </button>
    <b-modal v-model="isExportServiceModalActive" :width="640" scroll="keep">
      <div class="card" style="height: 700px">
        <div class="card-content">
          <div class="content">
            <b-field label="Clientes">
              <multiselect
                v-model="selectedCustomers"
                :options="allCustomers"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder="Selecione os serviços"
                label="name"
                track-by="id"
                :preselect-first="false"
              ></multiselect>
            </b-field>
            <b-field label="Selecione o período">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="dates"
                inline
                range
              >
              </b-datepicker>
            </b-field>
            <downloadexcel
              class="button field is-info is-light z-10"
              :data="data"
              :fields="fields"
              worksheet="Relatório"
              :name="fileName"
              :fetch="downloadReport"
            >
              Download Excel
            </downloadexcel>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- <div class="sm:flex sm:space-x-4">
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow
          transform
          transition-all
          mb-4
          w-full
          sm:w-1/3
          sm:my-8
        "
      >
        <div class="bg-white p-5">
          <div class="sm:flex sm:items-start">
            <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
              <h3 class="text-sm leading-6 font-medium text-gray-600">
                Receitas deste mês
                <b-icon
                  icon="arrow-top-right-thick"
                  style="color: #60ceb3"
                  size="is-small"
                >
                </b-icon>
              </h3>
              <p class="text-3xl font-bold text-black">
                R$
                {{
                  allFilterFinancialReports
                    ? parseFloat(allFilterFinancialReports.totalRecipe).toFixed(
                        2
                      )
                    : '---'
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow
          transform
          transition-all
          mb-4
          w-full
          sm:w-1/3
          sm:my-8
        "
      >
        <div class="bg-white p-5">
          <div class="sm:flex sm:items-start">
            <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
              <h3 class="text-sm leading-6 font-medium text-gray-600">
                Despesas deste mês
                <b-icon
                  icon="arrow-bottom-right-thick"
                  style="color: #ff5252"
                  size="is-small"
                >
                </b-icon>
              </h3>
              <p class="text-3xl font-bold text-black">
                R$
                {{
                  allFilterFinancialReports
                    ? parseFloat(
                        allFilterFinancialReports.totalExpense
                      ).toFixed(2)
                    : '---'
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        class="
          inline-block
          align-bottom
          bg-white
          rounded-lg
          text-left
          overflow-hidden
          shadow
          transform
          transition-all
          mb-4
          w-full
          sm:w-1/3
          sm:my-8
        "
      >
        <div class="bg-white p-5">
          <div class="sm:flex sm:items-start">
            <div class="text-center sm:mt-0 sm:ml-2 sm:text-left">
              <h3 class="text-sm leading-6 font-medium text-gray-600">
                Total deste mês
                <b-icon icon="chart-line-variant" size="is-small"> </b-icon>
              </h3>
              <p class="text-3xl font-bold text-black">
                R$
                {{
                  allFilterFinancialReports
                    ? parseFloat(allFilterFinancialReports.total).toFixed(2)
                    : '---'
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <table
      style="width: 100% !important    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;"
      class="z-10 container"
      id="customers"
    >
      <tr>
        <th>Categoria</th>
        <th>Janeiro</th>
        <th>Fevereiro</th>
        <th>Março</th>
        <th>Abril</th>
        <th>Maio</th>
        <th>Junho</th>
        <th>Julho</th>
        <th>Agosto</th>
        <th>Setembro</th>
        <th>Outubro</th>
        <th>Novembro</th>
        <th>Dezembro</th>
      </tr>
      <tr style="background: #60ceb3">
        <td><strong style="color: white">Receitas</strong></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <template v-for="(item, index) in allFilterFinancialReports.recipe">
        <tr @click="toggleCategory('recipe', index)" :key="index">
          <td>
            <strong>{{ index + 1 }}</strong>
            {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td style="font-style: italic">
              <strong style="margin-left: 8px"
                >{{ index + 1 }}.{{ indexSub + 1 }}</strong
              >
              {{ itemSub.name }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
      <template v-for="(item, index) in allFilterFinancialReports.service">
        <tr @click="toggleCategory('service', index)" :key="index">
          <td>
            <strong>{{ allFilterFinancialReports.recipe.length + 1 }}</strong>
            {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td style="font-style: italic">
              <strong style="margin-left: 8px"
                >{{ allFilterFinancialReports.recipe.length + 1 }}.{{
                  indexSub + 1
                }}</strong
              >
              {{ itemSub.name }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
      <tr style="background: #ff5252">
        <td><strong style="color: white">Despesas</strong></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <template v-for="(item, index) in allFilterFinancialReports.expense">
        <tr @click="toggleCategory('expense', index)" :key="index">
          <td>
            <strong>{{ index + 1 }}</strong> {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td>
              <strong style="margin-left: 8px"
                >{{ index + 1 }}.{{ indexSub + 1 }}</strong
              >
              {{ itemSub.name }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
      <template v-for="(item, index) in allFilterFinancialReports.infraction">
        <tr @click="toggleCategory('infraction', index)" :key="index">
          <td>
            <strong>{{ allFilterFinancialReports.expense.length + 1 }}</strong>
            {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td style="font-style: italic">
              <strong style="margin-left: 8px"
                >{{ allFilterFinancialReports.expense.length + 1 }}.{{
                  indexSub + 1
                }}</strong
              >
              {{ itemSub.name }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
      <template v-for="(item, index) in allFilterFinancialReports.maintenance">
        <tr @click="toggleCategory('maintenance', index)" :key="index">
          <td>
            <strong>{{ allFilterFinancialReports.expense.length + 3 }}</strong>
            {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td style="font-style: italic">
              <strong style="margin-left: 8px"
                >{{ allFilterFinancialReports.expense.length + 2 }}.{{
                  indexSub + 1
                }}</strong
              >
              {{ itemSub.name }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
      <template v-for="(item, index) in allFilterFinancialReports.fuelSupply">
        <tr @click="toggleCategory('fuelSupply', index)" :key="index">
          <td>
            <strong>{{ allFilterFinancialReports.expense.length + 4 }}</strong>
            {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td style="font-style: italic">
              <strong style="margin-left: 8px"
                >{{ allFilterFinancialReports.expense.length + 4 }}.{{
                  indexSub + 1
                }}</strong
              >
              {{ itemSub.name }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
      <template
        v-for="(item, index) in allFilterFinancialReports.driverPartner"
      >
        <tr @click="toggleCategory('driverPartner', index)" :key="index">
          <td>
            <strong>{{ allFilterFinancialReports.expense.length + 5 }}</strong>
            {{ item.name }} ({{
              getPercentageByValue(
                parseFloat(
                  allFilterFinancialReports.totalRecipe +
                    allFilterFinancialReports.totalExpense
                ),
                item.totalValue
              )
            }}%)
            <b-icon
              :icon="
                item.isNotActive
                  ? 'arrow-up-drop-circle-outline'
                  : 'arrow-down-drop-circle-outline'
              "
              style="color: #60ceb3"
              size="is-small"
            ></b-icon>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <template v-if="!item.isNotActive">
          <tr v-for="(itemSub, indexSub) in item.subcategories" :key="indexSub">
            <td style="font-style: italic">
              <strong style="margin-left: 8px"
                >{{ allFilterFinancialReports.expense.length + 5 }}.{{
                  indexSub + 1
                }}</strong
              >
              {{ itemSub.name.toUpperCase() }}
            </td>
            <td>R$ {{ parseFloat(itemSub.months[0].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[1].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[2].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[3].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[4].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[5].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[6].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[7].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[8].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[9].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[10].value).toFixed(2) }}</td>
            <td>R$ {{ parseFloat(itemSub.months[11].value).toFixed(2) }}</td>
          </tr>
        </template>
      </template>
    </table> -->
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/services/store/service'
import downloadexcel from 'vue-json-excel'
import XLSX from 'xlsx'

export default {
  name: 'FinancialReport',
  components: {
    downloadexcel,
  },
  data() {
    return {
      showDetailIcon: false,
      isCardModalActive: false,
      isExportServiceModalActive: false,
      allFilterFinancialReports: null,
      allFinancialServiceLaunch: [],
      selectedCustomers: '',
      fileName: 'file.xls',
      fields: {
        DATA: 'serviceDate',
        OS: 'serviceNumber',
        PASSAGEIRO: 'passengerName',
        MATRÍCULA: 'passengerRegistration',
        CC: 'costCenter',
        HORÁRIO: 'boardingTime',
        ITINERÁRIO: 'serviceItinerary',
        SOLICITANTE: 'requesterName',
        'ADICIONAL P/MUNICÍPIO': 'extraLocalValueFormat',
        'VALOR P/USUÁRIO': 'serviceValueFormat',
        'TOTAL VIAGEM': 'totalServiceValueFormat',
        'VALOR\nHORA PARADA': 'stopTimeValueFormat',
        'ADICIONAL\nPERÍODO NOTURNO': 'intervalValueFormat',
        'ADICIONAL\nFERIADO': 'holidayValueFormat',
        PEDÁGIO: 'serviceTollValueFormat',
        'MOTIVO': 'reason',
        'JUSTIFICATIVA': 'justification'
      },
      data: [],
      dates: [],
      loading: false,
      chartData: {
        labels: [
          'Janeiro',
          'Fevereiro',
          'Março',
          'Abril',
          'Maio',
          'Junho',
          'Julho',
          'Agosto',
          'Setembro',
          'Outubro',
          'Novembro',
          'Dezembro',
        ],
        datasets: [
          {
            label: 'Receitas (R$)',
            borderWidth: 1,
            backgroundColor: [
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
              'rgba(54, 162, 235, 0.2)',
            ],
            borderColor: [
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
              'rgba(54, 162, 235, 1)',
            ],
            pointBorderColor: '#2554FF',
            data: [],
          },
          {
            label: 'Despesas (R$)',
            borderWidth: 1,
            backgroundColor: [
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
              'rgba(255, 103, 103, 0.2)',
            ],
            borderColor: [
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
              'rgba(255, 103, 103, 1)',
            ],
            pointBorderColor: '#2554FF',
            data: [],
          },
        ],
      },
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('financialReports', ['allFinancialReports']),
    ...mapState('customers', ['allCustomers']),
  },
  methods: {
    ...mapActions('financialReports', ['getAllFinancialReports']),
    ...mapActions('customers', ['getAllCustomers']),
    async init() {
      // await this.getAllFinancialReports()
      await this.getAllCustomers()
      // this.allFilterFinancialReports = this.allFinancialReports

      // const result = await service.getAllFinancialLaunchServices()
      // this.allFinancialServiceLaunch = result.data
    },
    toggleCategory(type, index) {
      this.allFilterFinancialReports[type][index].isNotActive = this
        .allFilterFinancialReports[type][index].isNotActive
        ? false
        : true

      const allFinancialReportsTemp = this.allFilterFinancialReports

      this.allFilterFinancialReports = []

      this.allFilterFinancialReports = allFinancialReportsTemp
    },
    async downloadReport() {
      const result = await service.getReportService(
        this.selectedCustomers.id,
        new Date(this.dates[0]).toISOString().split('T')[0],
        new Date(this.dates[1]).toISOString().split('T')[0]
      )

      const resultData = result.data.map((x) => {
        const date = this.$moment(x.serviceDate).format('DD/MM/YYYY')
        return {
          ...x,
          serviceDate: date,
        }
      })

      this.data = resultData

      this.fileName = `Relatório de viagens ${this.selectedCustomers.name} (${
        new Date(this.dates[0]).toISOString().split('T')[0]
      } - ${new Date(this.dates[1]).toISOString().split('T')[0]}).xls`

      return resultData
    },
    downloadFile(bytes) {
      const url = window.URL.createObjectURL(new Blob([bytes]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `${this.fileName}`)
      document.body.appendChild(link)
      link.click()
    },
    getPercentageByValue(total, value) {
      total = parseInt(total)
      value = parseInt(value)

      if (!total || total === 0 || !value || value === 0) {
        return 0
      }

      return ((value / total) * 100).toFixed(2)
    },
  },
}
</script>

<style scoped>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td {
  min-width: 150px;
}

#customers td,
#customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even) {
  background-color: #f2f2f2;
}

#customers tr:hover {
  background-color: #ddd;
}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #607d8b;
  color: white;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
